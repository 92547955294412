.submitContainer{
    margin-top: 100px;
}

.submitContent{
    margin-bottom: 40px;
}
.backButton{
    /* margin-top: 40px; */
    padding: 10px 20px;
    height: 50px;
    width: 150px;
    background-color:rgb(33,66,41);
    color: white;
    display: flex;
    margin: auto;
}

