.app {
    display: flex;
    align-items: justify;
    justify-content: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
  }
  
  form{
      background-color: white;
      padding: 0px 60px;
      border-radius: 10px;
  }
  
  h1{
      color: rgb(33,66,41);
      text-align: center;
  }
  
  .submitButton{
      width: 100%;
      height: 50px;
      padding: 10px;
      border: none;
      background-color: rgb(33,66,41);
      color: white;
      border-radius: 5px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin-top: 15px;
      margin-bottom: 30px;
  }