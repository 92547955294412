.formInput{
    display: flex;
    flex-direction: column;
    width: 350px;
}

input{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid gray;
}


label{
    font-size: 12px;
    color: gray;
}